import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_0065e177 from 'nuxt_plugin_sentryserver_0065e177' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_caa72022 from 'nuxt_plugin_sentryclient_caa72022' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_webpush_3feba6f2 from 'nuxt_plugin_webpush_3feba6f2' // Source: ./web-push.js (mode: 'all')
import nuxt_plugin_index_335d4c3b from 'nuxt_plugin_index_335d4c3b' // Source: ./firebase/index.js (mode: 'all')
import nuxt_plugin_axios_5242f69a from 'nuxt_plugin_axios_5242f69a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_deviceplugin_228bb93e from 'nuxt_plugin_deviceplugin_228bb93e' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_pluginsallowservices91b83d8e_cd1ee416 from 'nuxt_plugin_pluginsallowservices91b83d8e_cd1ee416' // Source: ./plugins.allow-services.91b83d8e.ts (mode: 'all')
import nuxt_plugin_pluginsapi8e683036_2f3512ca from 'nuxt_plugin_pluginsapi8e683036_2f3512ca' // Source: ./plugins.api.8e683036.ts (mode: 'all')
import nuxt_plugin_pluginsaxios6035eb89_1a8a2a0a from 'nuxt_plugin_pluginsaxios6035eb89_1a8a2a0a' // Source: ./plugins.axios.6035eb89.ts (mode: 'all')
import nuxt_plugin_pluginseventbusnew0897651f_4b0fe0b6 from 'nuxt_plugin_pluginseventbusnew0897651f_4b0fe0b6' // Source: ./plugins.event-bus-new.0897651f.ts (mode: 'all')
import nuxt_plugin_pluginseventbus4e0a3a5c_646031ce from 'nuxt_plugin_pluginseventbus4e0a3a5c_646031ce' // Source: ./plugins.event-bus.4e0a3a5c.ts (mode: 'all')
import nuxt_plugin_pluginsfloatingvue32382abc_fe254fec from 'nuxt_plugin_pluginsfloatingvue32382abc_fe254fec' // Source: ./plugins.floating-vue.32382abc.ts (mode: 'all')
import nuxt_plugin_pluginsformatters05aeb052_1ce225d8 from 'nuxt_plugin_pluginsformatters05aeb052_1ce225d8' // Source: ./plugins.formatters.05aeb052.ts (mode: 'all')
import nuxt_plugin_pluginslastactivityclient4da6cdb0_fc16aa84 from 'nuxt_plugin_pluginslastactivityclient4da6cdb0_fc16aa84' // Source: ./plugins.last-activity.client.4da6cdb0.ts (mode: 'client')
import nuxt_plugin_pluginslastpaymentclient7f142f35_dadff97e from 'nuxt_plugin_pluginslastpaymentclient7f142f35_dadff97e' // Source: ./plugins.last-payment.client.7f142f35.ts (mode: 'client')
import nuxt_plugin_pluginslazyloadclient1d1f47be_16ff09ea from 'nuxt_plugin_pluginslazyloadclient1d1f47be_16ff09ea' // Source: ./plugins.lazy-load.client.1d1f47be.ts (mode: 'client')
import nuxt_plugin_pluginsmaskae236c6ac_bf420e22 from 'nuxt_plugin_pluginsmaskae236c6ac_bf420e22' // Source: ./plugins.maska.e236c6ac.ts (mode: 'all')
import nuxt_plugin_pluginspluralendings624ed918_2fc32659 from 'nuxt_plugin_pluginspluralendings624ed918_2fc32659' // Source: ./plugins.plural-endings.624ed918.ts (mode: 'all')
import nuxt_plugin_pluginsvuelidatef94c6524_0e26d74d from 'nuxt_plugin_pluginsvuelidatef94c6524_0e26d74d' // Source: ./plugins.vuelidate.f94c6524.ts (mode: 'all')
import nuxt_plugin_pluginsyamaps3d0780fa_7b5a2110 from 'nuxt_plugin_pluginsyamaps3d0780fa_7b5a2110' // Source: ./plugins.ya-maps.3d0780fa.ts (mode: 'all')
import nuxt_plugin_authextendaxios137deb78_d16f91ee from 'nuxt_plugin_authextendaxios137deb78_d16f91ee' // Source: ./auth.extend-axios.137deb78.ts (mode: 'all')
import nuxt_plugin_authstore9f4f4c1c_4391c5cc from 'nuxt_plugin_authstore9f4f4c1c_4391c5cc' // Source: ./auth.store.9f4f4c1c.ts (mode: 'all')
import nuxt_plugin_ymetricsclient_36a5c191 from 'nuxt_plugin_ymetricsclient_36a5c191' // Source: ./y-metrics.client.ts (mode: 'client')
import nuxt_plugin_gaclient_64a0b160 from 'nuxt_plugin_gaclient_64a0b160' // Source: ./ga.client.ts (mode: 'client')
import nuxt_plugin_analyticsclient_807b5e30 from 'nuxt_plugin_analyticsclient_807b5e30' // Source: ./analytics.client.ts (mode: 'client')
import nuxt_plugin_autopaymentplugin75b1ac58_4e7f3dd8 from 'nuxt_plugin_autopaymentplugin75b1ac58_4e7f3dd8' // Source: ./auto-payment.plugin.75b1ac58.ts (mode: 'all')
import nuxt_plugin_cartaxcalcstore998a46ec_5e430eaa from 'nuxt_plugin_cartaxcalcstore998a46ec_5e430eaa' // Source: ./car-tax-calc.store.998a46ec.ts (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"ru"},"title":"Сервис онлайн оплаты штрафов ГИБДД","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Штрафов.net поможет без регистрации узнать о штрафах в базе ГИБДД, ГИС ГМП и оплатить их онлайн со скидкой 25%. Просмотр фото нарушения и уведомления о новых штрафах. История оплаты и квитанции о погашении. Поиск по постановлению, номеру авто, правам или СТС"},{"name":"format-detection","content":"telephone=no"},{"name":"format-detection","content":"address=no"},{"name":"HandheldFriendly","content":"true"},{"name":"X-UA-Compatible","content":"ie=edge"},{"hid":"author","name":"author","content":"Штрафов.net Сервис онлайн оплаты штрафов ГИБДД"},{"hid":"og:title","name":"og:title","content":"Сервис онлайн оплаты штрафов ГИБДД"},{"hid":"og:site_name","name":"og:site_name","content":"Штрафов НЕТ"},{"hid":"og:description","name":"og:description","content":"Сервис Штрафов.net поможет узнать о наличии штрафов в базе данных ГИС ГМП, ГИБДД и других и оплатить эти штрафы картой со скидкой 25%."}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon\u002Ffavicon.ico"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.googleapis.com\u002F"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_0065e177 === 'function') {
    await nuxt_plugin_sentryserver_0065e177(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_caa72022 === 'function') {
    await nuxt_plugin_sentryclient_caa72022(app.context, inject)
  }

  if (typeof nuxt_plugin_webpush_3feba6f2 === 'function') {
    await nuxt_plugin_webpush_3feba6f2(app.context, inject)
  }

  if (typeof nuxt_plugin_index_335d4c3b === 'function') {
    await nuxt_plugin_index_335d4c3b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5242f69a === 'function') {
    await nuxt_plugin_axios_5242f69a(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_228bb93e === 'function') {
    await nuxt_plugin_deviceplugin_228bb93e(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsallowservices91b83d8e_cd1ee416 === 'function') {
    await nuxt_plugin_pluginsallowservices91b83d8e_cd1ee416(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsapi8e683036_2f3512ca === 'function') {
    await nuxt_plugin_pluginsapi8e683036_2f3512ca(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsaxios6035eb89_1a8a2a0a === 'function') {
    await nuxt_plugin_pluginsaxios6035eb89_1a8a2a0a(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseventbusnew0897651f_4b0fe0b6 === 'function') {
    await nuxt_plugin_pluginseventbusnew0897651f_4b0fe0b6(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseventbus4e0a3a5c_646031ce === 'function') {
    await nuxt_plugin_pluginseventbus4e0a3a5c_646031ce(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfloatingvue32382abc_fe254fec === 'function') {
    await nuxt_plugin_pluginsfloatingvue32382abc_fe254fec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsformatters05aeb052_1ce225d8 === 'function') {
    await nuxt_plugin_pluginsformatters05aeb052_1ce225d8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslastactivityclient4da6cdb0_fc16aa84 === 'function') {
    await nuxt_plugin_pluginslastactivityclient4da6cdb0_fc16aa84(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslastpaymentclient7f142f35_dadff97e === 'function') {
    await nuxt_plugin_pluginslastpaymentclient7f142f35_dadff97e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslazyloadclient1d1f47be_16ff09ea === 'function') {
    await nuxt_plugin_pluginslazyloadclient1d1f47be_16ff09ea(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsmaskae236c6ac_bf420e22 === 'function') {
    await nuxt_plugin_pluginsmaskae236c6ac_bf420e22(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginspluralendings624ed918_2fc32659 === 'function') {
    await nuxt_plugin_pluginspluralendings624ed918_2fc32659(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsvuelidatef94c6524_0e26d74d === 'function') {
    await nuxt_plugin_pluginsvuelidatef94c6524_0e26d74d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsyamaps3d0780fa_7b5a2110 === 'function') {
    await nuxt_plugin_pluginsyamaps3d0780fa_7b5a2110(app.context, inject)
  }

  if (typeof nuxt_plugin_authextendaxios137deb78_d16f91ee === 'function') {
    await nuxt_plugin_authextendaxios137deb78_d16f91ee(app.context, inject)
  }

  if (typeof nuxt_plugin_authstore9f4f4c1c_4391c5cc === 'function') {
    await nuxt_plugin_authstore9f4f4c1c_4391c5cc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ymetricsclient_36a5c191 === 'function') {
    await nuxt_plugin_ymetricsclient_36a5c191(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gaclient_64a0b160 === 'function') {
    await nuxt_plugin_gaclient_64a0b160(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_analyticsclient_807b5e30 === 'function') {
    await nuxt_plugin_analyticsclient_807b5e30(app.context, inject)
  }

  if (typeof nuxt_plugin_autopaymentplugin75b1ac58_4e7f3dd8 === 'function') {
    await nuxt_plugin_autopaymentplugin75b1ac58_4e7f3dd8(app.context, inject)
  }

  if (typeof nuxt_plugin_cartaxcalcstore998a46ec_5e430eaa === 'function') {
    await nuxt_plugin_cartaxcalcstore998a46ec_5e430eaa(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
